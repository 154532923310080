import { AjaxHandler, AjaxHeaders, AjaxOptions, AjaxResponse } from '@my-types';
import Apis from './apis';
import Consts from './consts';
import Envs from './envs';
import Storage from './storage';
import Utils from './utils';

/**
 * Ajax.
 * 1. fetch response.ok的时候会认为正确返回, 即promise被resolve,
 * 2. fetch response.ok不为true的时候会认为错误返回, 即promise被reject. 但response仍将被解析,
 * 3. fetch error的时候会认为错误返回, 即promise被reject. 但response不会被解析, 可能也并没有response,
 * 4. 其他异常的时候会认为错误返回, 即promise被reject. 但response不会被解析, 可能也并没有response,
 * 5. 由于error被reject的promise, 也会被伪装成一个JSON对象. 此对象符合标准异常格式.
 */
class Ajax {
	/**
	 * 从响应中读取headers
	 */
	private static buildResponseHeaders = (response: Response): AjaxHeaders => {
		const headers: AjaxHeaders = {};
		response.headers.forEach((value: string, key: string) => {
			headers[key] = value;
		});
		return headers;
	};
	/**
	 * 将响应构造成为标准响应格式
	 */
	private static buildResponseData = (response: Response, body: any): AjaxResponse => {
		return {
			headers: Ajax.buildResponseHeaders(response),
			body: body,
			status: response.status,
			statusText: response.statusText
		};
	};
	/**
	 * 处理响应数据
	 *
	 * @param {Response} response
	 * @param {string} responseType
	 * @param {function} successHandler 解析成功处理器
	 * @param {function} failHandler 解析失败处理器
	 */
	private static handleResponse = (
		response: Response,
		responseType: string | null,
		successHandler: AjaxHandler,
		failHandler: AjaxHandler
	): void => {
		const contentType = response.headers.get('content-type') || '';
		if (contentType.includes('json')) {
			responseType = 'json';
		} else if (contentType.includes('xml') || contentType.includes('text') || contentType.includes('html')) {
			responseType = 'text';
		}

		let extractor = null;
		switch (responseType) {
			case 'text':
				extractor = Response.prototype.text;
				break;
			case 'blob':
				extractor = Response.prototype.blob;
				break;
			default:
				// json
				extractor = Response.prototype.json;
				break;
		}
		extractor
			.call(response)
			.then(data => {
				successHandler(Ajax.buildResponseData(response, data));
			})
			.catch(ex => {
				Ajax.rejectWithError(ex, failHandler);
			});
	};
	/**
	 * 模拟一个标准的错误返回, 状态码是0
	 */
	private static rejectWithError = (error: Error, reject: AjaxHandler, opts?: { status?: number }): void => {
		reject({
			headers: {},
			body: {
				errors: [
					{
						code: Consts.FETCH_ERROR,
						description: `${error.name}: ${error.message}`
					}
				]
			},
			status: opts?.status || 0,
			statusText: 'Fetch error occurred.',
			// keep original error here
			error: error
		});
	};
	/**
	 * all promises
	 * @param {[Promise]} promises
	 */
	all<T>(promises: (T | PromiseLike<T>)[]): Promise<T[]> {
		return Promise.all(promises);
	}
	/**
	 * post data
	 * @param {string} url
	 * @param {*} data
	 * @param {*} options
	 */
	post(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
		return this.ajax(url, data, Object.assign({}, options, { method: 'POST' }));
	}
	/**
	 * get data
	 * @param {string} url
	 * @param {*} data
	 * @param {*} options
	 */
	get(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
		return this.ajax(
			url,
			data,
			Object.assign({}, options, {
				method: 'GET'
			})
		);
	}
	ajax(url: string, data: any, options: AjaxOptions): Promise<AjaxResponse> {
		const headers = options.headers || {};
		if (headers['Content-Type'] == null) {
			// 默认json格式
			headers['Content-Type'] = 'application/json';
		} else if (headers['Content-Type'] === false) {
			// 强制不需要类型设置
			delete headers['Content-Type'];
		}
		const retrieveAccount = this.appendAuth(headers, options.ignoreAuth);

		const opts = {
			method: (options.method || 'GET').toUpperCase(), //请求方式
			headers: headers, // 请求头设置
			credentials: options.credentials || 'same-origin', // 设置cookie是否一起发送
			mode: options.mode || 'cors', // 可以设置 cors, no-cors, same-origin
			redirect: options.redirect || 'follow', // follow, error, manual
			cache: options.cache || 'default', // 设置 cache 模式 (default, reload, no-cache)
			body: '' as any
		};

		// 默认响应body是json格式
		const responseType = options.dataType || 'json';
		if (opts.method === 'GET') {
			if (data) {
				// 如果有数据, 并且是get请求, 则拼接query string
				url += `?${this.generateQueryString(data)}`;
			}
			delete opts.body;
		} else if (Utils.isFormData(data)) {
			opts.body = data;
		} else {
			opts.body = JSON.stringify(data || {});
		}

		return new Promise((resolve, reject) => {
			fetch(this.getServiceLocation(url), opts as any)
				.then((response: Response) => {
					// 设置登录串
					// 设置backend版本号
					this.setAuth(response).setBackendVersion(response);

					if (response.ok) {
						const { ignoreRetrieveAccount = false } = options;
						if (retrieveAccount && ignoreRetrieveAccount === false) {
							// 需要再次获取账户信息
							this.retrieveAccount(response, responseType, resolve, reject);
						} else {
							// 不需要再次获取账户信息, 直接处理响应数据
							Ajax.handleResponse(response, responseType, resolve, reject);
						}
					} else {
						// 返回码是不成功, 需要解析response
						// 并且无论解析成功还是失败, 都必须以reject结束
						Ajax.handleResponse(response, responseType, reject, reject);
					}
				})
				.catch((e: Error) => {
					if (Utils.isFormData(data) && data.get('file') && e.message === 'Failed to fetch') {
						Ajax.rejectWithError(e, reject, { status: 413 });
					} else {
						Ajax.rejectWithError(e, reject);
					}
				});
		});
	}
	/**
	 * 重新获取账户信息. 没有返回, 通过回调实现.
	 */
	retrieveAccount(
		originalResponse: Response | null,
		responseType: string | null,
		resolve: AjaxHandler,
		reject: AjaxHandler
	) {
		return fetch(this.getServiceLocation(Apis.AUTH_RETRIEVE), {
			method: 'GET',
			headers: {
				Authorization: this.getAuth(),
				'Content-Type': 'application/json'
			},
			credentials: 'same-origin',
			mode: 'cors',
			redirect: 'follow',
			cache: 'default'
		})
			.then(retrieveResponse => {
				if (retrieveResponse.ok) {
					// 账户信息保存到本地
					return retrieveResponse
						.clone()
						.json()
						.then(data => {
							Envs.holdAccount(data.body);
							const queryString = Utils.fromQueryString();
                            //url中的样式优先
                            if (!(queryString && queryString.theme)) {
                                //后端有设置主题则使用后端主题
								if (data.body.theme) {
									Envs.application()!.changeTheme(data.body.theme);
								}
}
							Envs.application()!.changeThemeColor(data.body.themeColor);                            
							if (data.body.shortcutsFloating) {
								Storage.Profile.set(Consts.SHORTCUTS_FLOATING_KEY, data.body.shortcutsFloating);
							}
							if (originalResponse) {
								// 处理原先的响应
								Ajax.handleResponse(originalResponse, responseType, resolve, reject);
							} else {
								// 没有指定原响应, 直接处理自己
								Ajax.handleResponse(retrieveResponse, 'json', resolve, reject);
							}
						})
						.catch(ex => Ajax.rejectWithError(ex, reject));
				} else {
					// 返回码是不成功, 需要解析response
					// 并且无论解析成功还是失败, 都必须以reject结束
					Ajax.handleResponse(retrieveResponse, 'json', reject, reject);
				}
			})
			.catch(e => {
				// 没有拿到账户信息, 直接报错
				Ajax.rejectWithError(e, reject);
			});
	}

	/**
	 * 产生query string
	 */
	private generateQueryString(obj: any): string {
		return Utils.toQueryString(obj);
	}
	/**
	 * 获取服务位置
	 */
	getServiceLocation(relativePath?: string | null): string {
		if (relativePath && (relativePath.startsWith('https://') || relativePath.startsWith('http://'))) {
			// 已经是绝对路径
			return relativePath;
		}

		let url = window.location;

		let port = url.port;
		if (process.env.REACT_APP_AJAX_SERVER_PORT) {
			port = `:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
		} else if (port) {
			port = `:${port}`;
		}
		let hostname = url.hostname;
		if (process.env.REACT_APP_AJAX_SERVER_HOST) {
			hostname = process.env.REACT_APP_AJAX_SERVER_HOST;
		}
		let protocol = url.protocol;
		if (process.env.REACT_APP_AJAX_SERVER_PROTOCOL) {
			protocol = process.env.REACT_APP_AJAX_SERVER_PROTOCOL;
		}
		let context = process.env.REACT_APP_AJAX_SERVER_CONTEXT || '/csms';
		let location = `${protocol}//${hostname}${port}${context}`;
		if (relativePath) {
			return location + relativePath;
		} else {
			return location;
		}
	}
	/**
	 * 添加auth信息到请求头
	 *
	 * @param headers 必须是已经创建好的json对象
	 * @param ignoreAuth 默认false
	 *
	 * @return 是否需要在请求之后额外请求账户信息
	 */
	private appendAuth(headers: AjaxHeaders, ignoreAuth: boolean = false): boolean {
		// 如果需要忽略Authorization信息, 则必须显式声明
		if (ignoreAuth === true) {
			return false;
		}

		let retrieveAccount = false;

		// session中没有account信息 或 比对ticket信息不同，重新获取account信息覆盖当前session
		const { ticket = '' } = Utils.fromQueryString();
		if (Envs.isTatToken() && (!Envs.findAccount().accountName || ticket !== this.getAuth())) {
			retrieveAccount = true;
		}

		if (!headers[Consts.AUTH_KEY]) {
			// 从query string中获取免登陆串
			let query = Utils.fromQueryString();
			if (query.ticket) {
				headers[Consts.AUTH_KEY] = query.ticket;
			}
			if (query.token) {
				headers[Consts.CSMD_TOKEN_KEY] = query.token;
			}
			if (query.delegated) {
				headers[Consts.CSMD_DELEGATED_KEY] = query.delegated;
			}

			// 使用免登陆串进行登录, 并且免登陆串的账户信息与当前session的账户信息不同
			// 需要额外发一次请求带回用户信息, 以便覆盖当前session的账户信息
			if (query.delegated && Envs.findAccount().accountName !== query.delegated) {
				retrieveAccount = true;
			}
		}

		if (!headers[Consts.AUTH_KEY]
			&& this.getAuth()) {
			// 从session中获取auth串
			headers[Consts.AUTH_KEY] = this.getAuth();
		}
		
		return retrieveAccount;
	}
	/**
	 * 从session storage中获取authoration串
	 */
	getAuth(): string {
		return Storage.Auth.session().get(Consts.AUTH_KEY);
	}
	/**
	 * 设置登录串
	 */
	private setAuth(response: Response): this {
		let authString = response.headers.get(Consts.AUTH_KEY);
		if (authString) {
			Storage.Auth.session().set(Consts.AUTH_KEY, authString);
		}
		return this;
	}
	/**
	 * 是否已经登陆过
	 */
	isAuthorized(): boolean {
		return this.getAuth() != null;
	}
	/**
	 * 设置Backend版本
	 * @param {Response} response
	 */
	private setBackendVersion(response: Response): this {
		const ver = response.headers.get(Consts.CSMS_APP_VER_KEY);
		if (ver) {
			Storage.Env.session().set(Consts.BACKEND_VER_STORAGE_KEY, ver);
			Storage.Env.local().set(Consts.BACKEND_VER_STORAGE_KEY, ver);
		}
		return this;
	}
}

export default new Ajax();
